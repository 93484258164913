module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/home/runner/work/docs/docs/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/home/runner/work/docs/docs/node_modules/@primer/gatsby-theme-doctocat/src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f3da5bc9ee21801506bcf83c84f79ae4"},
    },{
      plugin: require('../node_modules/@primer/gatsby-theme-doctocat/gatsby-browser.js'),
      options: {"plugins":[],"defaultBranch":"main","repoRootPath":"./content"},
    }]
