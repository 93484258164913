// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---content-example-index-mdx": () => import("./../../content/example/index.mdx" /* webpackChunkName: "component---content-example-index-mdx" */),
  "component---content-example-page-2-mdx": () => import("./../../content/example/page-2.mdx" /* webpackChunkName: "component---content-example-page-2-mdx" */),
  "component---content-index-mdx": () => import("./../../content/index.mdx" /* webpackChunkName: "component---content-index-mdx" */),
  "component---content-page-1-mdx": () => import("./../../content/page-1.mdx" /* webpackChunkName: "component---content-page-1-mdx" */)
}

